
import client1 from "../constant/AxiosInstance";
import client2 from "../constant/AxiosInstance2";
import {
   add_application, add_client, add_client_subscription, add_module, add_module_screen, add_role,
   add_screen, add_subcription, add_user, create_campaign_master, create_channel_master, delete_application,
   delete_campaign_master, delete_channel_master, delete_client, delete_client_subscription, delete_module,
   delete_role, delete_subcription, delete_user, get_application, get_campaign_master, get_channel_master,
   get_client, get_client_subscription, get_json, get_module, get_module_screen, get_role, get_screen,
   get_subcription, get_user, update_application, update_campaign_master, update_channel_master,
   update_client, update_client_subscription, update_module, update_module_sceen, update_role, update_screen,
   update_subcription, update_user, client_campaign, get_client_campaign, update_client_campaign,
   delete_client_campaign,
   get_transaction,
   delete_transaction,
   create_transaction,
   delete_movement,
   get_movement,
   create_movement,
   gst_slab,
   gst_treatment,
   org_type,
   company_type,
   store_type,
   status_api,
   taskCategory,
   document_type_api,
   scenario_mapping,
   inspection_type,
   HSN_master,
   adjustment_reason,
   product_type,
   delete_user_2,
   vendor_type,
   industry_type,
   break_code,
   location_type
} from "../constant/index"
//application api's 

export const save_app = (payload) => {
   return client1.post(add_application, payload)
}

export const get_app = (payload) => {
   return client1.get(get_application, { params: payload })
}

export const update_app = (id, payload) => {
   return client1.put(update_application, payload, { params: id })
}

export const delete_app = (payload) => {
   return client1.delete(delete_application, { params: payload })
}

//Module api 
export const save_module_api = (payload) => {
   return client1.post(add_module, payload)
}

export const get_module_api = (payload) => {
   return client1.get(get_module, { params: payload })
}

export const update_module_api = (id, payload) => {
   return client1.put(update_module, payload, { params: id })
}

export const delete_module_api = (payload) => {
   return client1.delete(delete_module, { params: payload })
}

//role api 

export const save_role_api = (payload) => {
   return client1.post(add_role, payload)
}

export const get_role_api = (payload) => {
   return client1.get(get_role, { params: payload })
}

export const update_role_api = (id, payload) => {
   return client1.put(update_role, payload, { params: id })
}

export const delete_role_api = (payload) => {
   return client1.delete(delete_role, { params: payload })
}

//screen api

export const save_screen_api = (payload) => {
   return client1.post(add_screen, payload)
}

export const get_screen_api = (payload) => {
   return client1.get(get_screen, { params: payload })
}

export const update_screen_api = (id, payload) => {
   return client1.put(update_screen, payload, { params: id })
}

//user api 

export const save_user_api = (payload) => {
   return client1.post(add_user, payload)
}

export const get_user_api = (payload) => {
   return client1.get(get_user, { params: payload })
}

export const update_user_api = (id, payload) => {
   return client1.put(update_user, payload, { params: id })
}

export const delete_user_api = (payload) => {
   return client1.delete(delete_user, { params: payload })
}

export const delete_user_api_2 = (payload) => {
   return client2.delete(delete_user_2, { params: payload })
}


//Subcriptions api's 

export const save_subcription_api = (payload) => {
   return client1.post(add_subcription, payload)
}

export const get_subcription_api = (payload) => {
   return client1.get(get_subcription, { params: payload })
}

export const update_subcription_api = (id, payload) => {
   return client1.put(update_subcription, payload, { params: id })
}

export const delete_subcription_api = (payload) => {
   return client1.delete(delete_subcription, { params: payload })
}

//client apis' 


export const save_client_api = (payload) => {
   return client1.post(add_client, payload)
}

export const get_client_api = (payload) => {
   return client1.get(get_client, { params: payload })
}

export const update_client_api = (id, payload) => {
   return client1.put(update_client, payload, { params: id })
}

export const delete_client_api = (payload) => {
   return client1.delete(delete_client, { params: payload })
}

//module screen api's

export const save_module_screen_api = (payload) => {
   return client1.post(add_module_screen, payload)
}

export const get_module_screen_api = (payload) => {
   return client1.get(get_module_screen, { params: payload })
}

export const update_module_screen_api = (id, payload) => {
   return client1.put(update_module_sceen, payload, { params: id })
}

//client subcription api's 

export const save_client_subcription_api = (payload) => {
   return client1.post(add_client_subscription, payload)
}

export const get_client_subcription_api = (payload) => {
   return client1.get(get_client_subscription, { params: payload })
}

export const update_client_subcription_api = (id, payload) => {
   return client1.put(update_client_subscription, payload, { params: id })
}

export const delete_client_subcription_api = (payload) => {
   return client1.delete(delete_client_subscription, { params: payload })
}

//Get screen json api 

export const get_json_api = () => {
   return client1.get(get_json)
}

// channel master api's 

export const save_channel_master_api = (payload) => {
   return client2.post(create_channel_master, payload)
}

export const get_channel_master_api = (payload) => {
   return client2.get(get_channel_master, { params: payload })
}

export const update_channel_master_api = (id, payload) => {
   return client2.put(update_channel_master, payload, { params: id })
}

export const delete_channel_master_api = (payload) => {
   return client2.delete(delete_channel_master, { params: payload })
}

// campaign Master 

export const save_campaign_master_api = (payload) => {
   return client2.post(create_campaign_master, payload)
}

export const get_campaign_master_api = (payload) => {
   return client2.get(get_campaign_master, { params: payload })
}

export const update_campaign_master_api = (id, payload) => {
   return client2.put(update_campaign_master, payload, { params: id })
}

export const delete_campaign_master_api = (payload) => {
   return client2.delete(delete_campaign_master, { params: payload })
}

//campaing linking
export const link_client_campaign_api = (payload) => {
   return client2.post(client_campaign, payload)
}

export const update_client_campaign_api = (payload) => {
   return client2.put(update_client_campaign, payload)
}

export const get_client_campaign_api = (payload) => {
   return client2.get(get_client_campaign, { params: payload })
}

export const delete_client_campaign_api = (payload) => {
   return client2.delete(delete_client_campaign, { params: payload })
}

//transaction api's 


export const save_transaction_api = (payload) => {
   return client2.post(create_transaction, payload)
}

export const get_transaction_api = (payload) => {
   return client2.get(get_transaction, { params: payload })
}

export const delete_transaction_api = (id) => {
   return client2.delete(`${delete_transaction}/${id}`)
}

//movement api's 

export const save_movement_api = (payload) => {
   return client2.post(create_movement, payload)
}

export const get_movement_api = (payload) => {
   return client2.get(get_movement, { params: payload })
}

export const delete_movement_api = (id) => {
   return client2.delete(`${delete_movement}/${id}`)
}

// GST slab master
export const get_gst_slab = (payload) => {
   return client2.get(gst_slab, { params: payload });
}

export const save_gst_slab = (payload) => {
   return client2.post(gst_slab, payload);
}

export const update_gst_slab = (payload) => {
   return client2.patch(gst_slab, payload);
}

export const delete_gst_slab = (payload) => {
   return client2.delete(gst_slab, { params: payload });
}

//GST Treatment
export const get_gst_treatment = (payload) => {
   return client2.get(gst_treatment, { params: payload });
}

export const save_gst_treatment = (payload) => {
   return client2.post(gst_treatment, payload);
}

export const update_gst_treatment = (payload) => {
   return client2.patch(gst_treatment, payload);
}

export const delete_gst_treatment = (payload) => {
   return client2.delete(gst_treatment, { params: payload });
}

// Organisation type master
export const get_org_type = (payload) => {
   return client2.get(org_type, { params: payload });
}

export const save_org_type = (payload) => {
   return client2.post(org_type, payload);
}

export const update_org_type = (payload) => {
   return client2.patch(org_type, payload);
}

export const delete_org_type = (payload) => {
   return client2.delete(org_type, { params: payload });
}

// Company type master
export const get_company_type = (payload) => {
   return client2.get(company_type, { params: payload });
}

export const save_company_type = (payload) => {
   return client2.post(company_type, payload);
}

export const update_company_type = (payload) => {
   return client2.patch(company_type, payload);
}

export const delete_company_type = (payload) => {
   return client2.delete(company_type, { params: payload });
}

// Store type master
export const get_store_type = (payload) => {
   return client2.get(store_type, { params: payload });
}

export const save_store_type = (payload) => {
   return client2.post(store_type, payload);
}

export const update_store_type = (payload) => {
   return client2.patch(store_type, payload);
}

export const delete_store_type = (payload) => {
   return client2.delete(store_type, { params: payload });
}


//Status Master 

export const getStatusMaster = (payload) => {
   return client2.get(status_api, { params: payload });
}

export const saveStatusMaster = (payload) => {
   return client2.post(status_api, payload);
}

export const updateStatusMaster = (payload) => {
   return client2.patch(status_api, payload);
}

export const deleteStatusMaster = (payload) => {
   return client2.delete(status_api, { params: payload });
}

//Task Category

export const getTaskCategory = (payload) => {
   return client2.get(taskCategory, { params: payload });
}

export const saveTaskCategory = (payload) => {
   return client2.post(taskCategory, payload);
}

export const updateTaskCategory = (payload) => {
   return client2.patch(taskCategory, payload);
}

export const deletTaskCategory = (payload) => {
   return client2.delete(taskCategory, { params: payload });
}



// doucment type
export const get_document_type = (payload) => {
   return client2.get(document_type_api, { params: payload })
}

export const update_document_type = (payload) => {
   return client2.patch(document_type_api, payload)
}

export const create_document_type = (payload) => {
   return client2.post(document_type_api, payload)
}

export const delete_document_type = (payload) => {
   return client2.delete(document_type_api, { params: payload })
}

//Scenario Mapping api's 

export const save_scenario_mapping_api = (payload) => {
   return client2.post(scenario_mapping, payload)
}

export const get_scenario_mapping_api = (payload) => {
   return client2.get(scenario_mapping, { params: payload })
}

export const delete_scenario_mapping_api = (id) => {
   return client2.delete(`${scenario_mapping}/${id}`)
}


// Inspection type master
export const get_inspection_type = (payload) => {
   return client2.get(inspection_type, { params: payload });
}

export const create_inspection_type = (payload) => {
   return client2.post(inspection_type, payload);
}

export const update_inspection_type = (payload) => {
   return client2.patch(inspection_type, payload);
}

export const delete_inspection_type = (payload) => {
   return client2.delete(inspection_type, { params: payload });
}


//HSN Master                            
export const gethsn_master = (payload) => {
   return client2.get(HSN_master, { params: payload });
}

export const createhsn_master = (payload) => {
   return client2.post(HSN_master, payload);
}

export const updatehsn_master = (payload) => {
   return client2.patch(HSN_master, payload);
}

// Adjustment reason
export const get_adjustment_reason = (payload) => {
   return client2.get(adjustment_reason, { params: payload });
}

export const save_adjustment_reason = (payload) => {
   return client2.post(adjustment_reason, payload);
}

export const update_adjustment_reason = (payload) => {
   return client2.patch(adjustment_reason, payload);
}

export const delete_adjustment_reason = (payload) => {
   return client2.delete(adjustment_reason, { params: payload });
}

// Product type
export const get_product_type = (payload) => {
   return client2.get(product_type, { params: payload });
}

export const save_product_type = (payload) => {
   return client2.post(product_type, payload);
}

export const update_product_type = (payload) => {
   return client2.patch(product_type, payload);
}

export const delete_product_type = (payload) => {
   return client2.delete(product_type, { params: payload });
}


// Vendor type master
export const get_vendor_type = (payload) => {
   return client2.get(vendor_type, { params: payload });
}

export const save_vendor_type = (payload) => {
   return client2.post(vendor_type, payload);
}

export const update_vendor_type = (payload) => {
   return client2.patch(vendor_type, payload);
}

export const delete_vendor_type = (payload) => {
   return client2.delete(vendor_type, { params: payload });
}


// Industry type master
export const get_industry_type = (payload) => {
   return client2.get(industry_type, { params: payload });
}

export const save_industry_type = (payload) => {
   return client2.post(industry_type, payload);
}

export const update_industry_type = (payload) => {
   return client2.patch(industry_type, payload);
}

export const delete_industry_type = (payload) => {
   return client2.delete(industry_type, { params: payload });
}

// Industry type master
export const get_break_code = (payload) => {
   return client2.get(break_code, { params: payload });
}

export const save_break_code = (payload) => {
   return client2.post(break_code, payload);
}

export const update_break_code = (payload) => {
   return client2.patch(break_code, payload);
}

export const delete_break_code = (payload) => {
   return client2.delete(break_code, { params: payload });
}

// Location type
export const getLoctionType = (payload) => {
   return client2.get(location_type, { params: payload });
}

export const saveLocationType = (payload) => {
   return client2.post(location_type, payload);
}

export const updateLocationType = (payload) => {
   return client2.patch(location_type, payload);
}

export const deleteLocationType = (payload) => {
   return client2.delete(location_type, { params: payload });
}
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_vendor_type } from "../../../api";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Vendor type is required!"),
    vendor_cost: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ) // Converts empty string to null
      .typeError("Vendor cost must be a number") // Ensures input is a number
      .nullable() // Accepts null as a valid value
      .notRequired(),
  })
  .required();

function EditVendorType(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        type_name: data.name.trim(),
        vendor_cost: data.vendor_cost ? data.vendor_cost : null,

      };

      update_vendor_type(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Vendor type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchVendorTypeList(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={"Edit Vendor Type"}
        className="editIcon me-2"
      >
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Vendor Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {"Vendor Type"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.name ? "form-control  is-invalid" : "form-control "
                  }
                  id="name"
                  name="name"
                  defaultValue={props.data?.type_name}
                  placeholder="Enter vendor type"
                  {...register("name")}
                />
                <span className="text-danger err-msg">
                  {errors.name?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {"Vendor Cost"}
                {/* <span className="text-danger">*</span> */}
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.vendor_cost
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="vendor_cost"
                  name="vendor_cost"
                  defaultValue={props.data?.vendor_cost}
                  placeholder="Enter Vendor Cost"
                  {...register("vendor_cost")}
                />
                <span className="text-danger err-msg">
                  {errors.vendor_cost?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditVendorType;
